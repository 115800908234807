<template>
  <travio-center-container gridWidth="1/2" :pageTitle="pageTitle">
    <vx-card :title="widgetName">
      <div class="vx-row mt-4 mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Widget Title*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" name="Widget Title" v-model="form.widgetTitle" v-validate="'required|max:100'" :disabled="readOnly"/>
          <span class="text-danger text-sm">{{ errors.first('Widget Title') }}</span>
        </div>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Width*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="selectedCssWidth" @input="setSelectedCssWidth" :options="widthOptions" v-validate="'required'" name="Width"/>
          <span class="text-danger text-sm">{{ errors.first('Width') }}</span>
        </div>
      </div>
       <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Custom Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
        <vs-checkbox v-model="useCustomDate" @change='resetDateRange()'>            
          </vs-checkbox> 
        </div>
      </div>
      <div v-if="!useCustomDate" class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date Range*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="selectedDateRange" @input="setSelectedDateRange" :options="dateRangeOptions" v-validate="'required'" name="Date Range" />
          <span class="text-danger text-sm">{{ errors.first('Date Range') }}</span>
        </div>       
      </div>

      <div v-if="useCustomDate" class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Date Range*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
         <flat-pickr
                    v-model="form.customDateRange"
                    class="vx-col w-full"
                    name="Custom Date Range"                   
                    :config="{
                      mode: 'range',
                      maxDate: 'today',
                    }"     
                    v-validate="'required'" 
                  />
          <span class="text-danger text-sm">{{ errors.first('Custom Date Range') }}</span>
        </div>
      </div>

      <!-- <slot :form="form"></slot> -->
      <component
          v-if="componentEditor"
          @changed = "handleChildChanged"
          :is="componentEditor" 
          v-bind="{ formData: additionalSettingsInitValue }"
          ref="childEditorComponent" >
      </component>

      <!-- Applications -->
      <vx-card v-if="isCompanyDashboard" class="mt-base" no-shadow card-border>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <!-- <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" /> -->
              <span class="font-medium text-lg leading-none">Applications</span>
            </div>
            <vs-divider class="mb-0" />
          </div>
        </div>

        <div v-for="(application, applicationIndex) in applications" 
          :key="applicationIndex" class="mt-4">
          
          <vs-checkbox v-model="form.selectedApplicationIds" :vs-value="application.id" :disabled="readOnly">
            {{application.name}}
          </vs-checkbox>  
          
        </div>
      </vx-card>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button v-if="!readOnly" @click="handleCancel" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button v-if="!readOnly" @click="handleSubmit" class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>

import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import theLodash from 'lodash'
import { Form } from '@/core-modules/form-framework/Form.js'
import flatPickr from "vue-flatpickr-component";
import companyDashboardStore from './companyDashboardStore.js'
import SearchLocationWidgetEditor from './widgets/search-location-map/SearchLocationWidgetEditor.vue'
import TravelDurationWidgetEditor from './widgets/travel-duration/TravelDurationWidgetEditor.vue'
import TravellerCountWidgetEditor from './widgets/traveller-count/TravellerCountWidgetEditor.vue'
import SearchDurationWidgetEditor from './widgets/search-duration/SearchDurationWidgetEditor.vue'


export default {
  // see https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
  props: {
    readOnly: { type: Boolean, default: false },
    widgetInstanceId: { type: Number, default: 0 },
    componentEditor: { type: String, default: null },
    apiEndpoint: { type: String, required: true },
    dashboardId: { type: Number, required: true, default: null },
    widgetId: { type: Number, default: null },
    widgetName: { type: String, required: true },
    applicationId: { type: Number }
  },
  components: {
    SearchLocationWidgetEditor,
    TravelDurationWidgetEditor,
    TravellerCountWidgetEditor,
    SearchDurationWidgetEditor,
    flatPickr
  },
  data () {
    return {
      form: new Form({
        widgetId: 0,
        orderId: 0,
        widgetTitle: '',
        selectedApplicationIds: [],
        dateRangeId: null,
        customDateRange:null,
        cssWidth: ''
      }),
      selectedDateRange: null,
      useCustomDate: false,
      //customDateRange : null,
      selectedCssWidth: null,
      additionalSettingsInitValue: {}
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser;
    },
    applications () {
      return this.$store.state.AppActiveUser.applications
    },
    dateRangeOptions () {
      return this.$store.state.lookups.widgetDateRangeOptions
    },
    widthOptions () {
      return this.$store.state.lookups.widgetWidthOptions
    },
    pageTitle () {
      return this.widgetInstanceId == 0 ? 'Add Widget' : 'Edit Widget'
    },
    dashboardStore () {
      return this.isCompanyDashboard
				? this.$store.state.companyDashboardStore
				: this.$store.state.applicationDashboardStore
    },
    activeDashboard () {
      return this.dashboardStore.activeDashboard
    },
    isCompanyDashboard () {
      return !this.applicationId
    }
  },
  mounted () {    
    if (this.widgetInstanceId == 0){
      this.form.dashboardId = this.dashboardId
      this.form.widgetId = this.widgetId
      this.form.orderId = 99
      return
    }
    
    const endpointUrl = this.isCompanyDashboard
        ? `api/companies/${this.activeUserInfo.companyId}/widgets/${this.widgetInstanceId}`
        : `api/applications/${this.applicationId}/widgets/${this.widgetInstanceId}`

    this.$vs.loading();
    this.$http.get(endpointUrl)
      .then(response => {
        // If custom date range was selected, set useCustomDate to true
        if(response.data.customDateRange && !response.data.customDateRange.startsWith("{D")){
          this.useCustomDate = true;
        }  
        // Response will always have an 'additionalSettings' that have dynamic props
        // Child component widget, w/c is dynamic, will manage the additionalSettings data, hence the separation
        this.additionalSettingsInitValue = (response.data.additionalSettings && JSON.parse(JSON.stringify(response.data.additionalSettings))) || {};
        delete response.data.additionalSettings
        // Since 'additionalSettings' is separated we need to flatten this to our form
        this.form = new Form(Object.assign(this.form.data(), response.data, this.additionalSettingsInitValue))
        this.selectedDateRange = this.dateRangeOptions.find(x => x.code == response.data.dateRangeId) || {};
        this.selectedCssWidth = this.widthOptions.find(x => x.code == response.data.cssWidth) || {};
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
  },
  methods: {
    setSelectedDateRange (value) {
      this.form.dateRangeId = value ? value.code : null
    },
    setSelectedCssWidth (value) {
      this.form.cssWidth = value ? value.code : null
    },
    handleChildChanged (value) {
      this.form = new Form(Object.assign(this.form.data(), value))
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result && this.widgetInstanceId > 0) {
          this.editWidget()
        } else if (result && this.widgetInstanceId == 0) {
          this.addWidget()
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }

      })
    },
    editWidget() {      
      const endpointUrl = this.isCompanyDashboard
        ? `api/companies/${this.activeUserInfo.companyId}/widgets/${this.widgetInstanceId}/${this.apiEndpoint}`
        : `api/applications/${this.applicationId}/widgets/${this.widgetInstanceId}/${this.apiEndpoint}`

      this.$vs.loading();
      //either dateRangeId or customDateRange should have data
       if(this.form.dateRangeId){
        this.form.customDateRange = null;
      }
      if(this.form.customDateRange){
        this.form.dateRangeId = null;
      }
      this.$http.put(endpointUrl, this.form.data() )
      .then(response => {
        this.$_notifySuccess('Successfully updated widget.')
        const routeName = this.isCompanyDashboard ? 'company-dashboard' : 'application-dashboard'
        const routeParams = this.isCompanyDashboard
            ? { companyId: this.activeUserInfo.companyId, dashboardId: this.dashboardId }
            : { applicationId: this.applicationId, dashboardId: this.dashboardId }
        this.$router.push({name: routeName, params: routeParams })
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    addWidget () {
      const endpointUrl = this.isCompanyDashboard
        ? `api/companies/${this.activeUserInfo.companyId}/dashboards/${this.dashboardId}/widgets/${this.widgetId}/${this.apiEndpoint}`
        : `api/applications/${this.applicationId}/dashboards/${this.dashboardId}/widgets/${this.widgetId}/${this.apiEndpoint}`

      this.$vs.loading();
      //either dateRangeId or customDateRange should have data
       if(this.form.dateRangeId){
        this.form.customDateRange = null;
      }
      if(this.form.customDateRange){
        this.form.dateRangeId = null;
      }
      this.$http.post( endpointUrl, this.form.data())
      .then(response => {
          this.$_notifySuccess('Successfully updated widget.')
          const routeName = this.isCompanyDashboard ? 'company-dashboard' : 'application-dashboard'
          const routeParams = this.isCompanyDashboard
            ? { companyId: this.activeUserInfo.companyId, dashboardId: this.dashboardId }
            : { applicationId: this.applicationId, dashboardId: this.dashboardId }
          this.$router.push({ name: routeName, params: routeParams })
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    handleCancel () {
      if (this.isCompanyDashboard) {
        this.$router.push(`/companies/${this.activeUserInfo.companyId}`)
      } else {
        this.$router.push(`/applications/${this.applicationId}`)
      }
    },
    handleEditMode () {
      if (this.isCompanyDashboard) {
        this.$router.push(`/companies/${this.activeUserInfo.companyId}/widgets/${this.$route.params.widgetInstanceId}/edit`)
          .catch((error) => { console.error(error) })
      } else {
        this.$router.push(`/applications/${this.applicationId}/widgets/${this.$route.params.widgetInstanceId}/edit`)
          .catch((error) => { console.error(error) })
      }
        
    },
    resetDateRange(){
      if(!this.form.useCustomDate){
        this.form.customDateRange = null;
      }
      if(this.form.useCustomDate ){
        this.form.dateRangeId = null;
      }
    }
  }

}
</script>

<style>

</style>