<template>
  <travio-center-container pageTitle="Company Settings">
    <vx-card>
      <div class="flex flex-wrap items-center mb-base">
        <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
        <div>
          <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
          <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload logo</vs-button>
          <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
          <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.companyName" v-validate="'required|max:100'" name="Company Name" />
          <span class="text-danger text-sm">{{ errors.first('Company Name') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Company Type</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select :value="selectedCompanyType" @input="setSelectedCompanyType" placeholder="CompanyType" :options="companyTypeOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address 1</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.address1" name="Address 1"  v-validate="'max:250'" />
          <span class="text-danger text-sm">{{ errors.first('Address 1') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address 2</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.address2" name="Address 2"  v-validate="'max:250'" />
          <span class="text-danger text-sm">{{ errors.first('Address 2') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>City</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.city" name="City"  v-validate="'max:50'" />
          <span class="text-danger text-sm">{{ errors.first('City') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>County / State</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.state" name="State"  v-validate="'max:50'" />
          <span class="text-danger text-sm">{{ errors.first('State') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Country</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select class="style-chooser" :value="selectedCountry" @input="setSelectedCountry" placeholder="Country" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Require Users to 2FA</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <!-- <vs-checkbox class="inline-flex" v-model="dummy2">Enable 2FA</vs-checkbox> -->
          <vs-switch class="" v-model="form.requireUsersTo2FA" />
        </div>
      </div>

      <div class="lg:float-left mt-4">
        <span class="text-sm text-danger">*Required Field</span>
      </div>

      <div class="flex flex-wrap justify-end">
        <vs-button v-if="userHasPermission('company_settings_edit')" @click='submitSettings' class="">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>

</template>

<script>
import vSelect from 'vue-select'
import { Form } from '@/core-modules/form-framework/Form.js'
import TpNotificationMixin from '@/components/travio-pro/TpNotificationMixin.vue'
import useTravioAccessControl from '@/components/travio-pro/useTravioAccessControl.js';

export default {
  components: {
    vSelect
  },
  mixins: [TpNotificationMixin],
  data () {
    return {
      form: new Form({
        companyName: '',
        companyType: null,
        logoUrl: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        countryCode: null,
        requireUsersTo2FA: false
      }),
      selectedCountry: {},
      selectedCompanyType: {},
      dummy: '',
      dummy2: false
    }
  },
  setup () {
    const { userHasPermission } = useTravioAccessControl();
    return { userHasPermission }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    companyTypeOptions () {
      return this.$store.state.lookups.companyTypeOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  mounted () {
     //Check if user has acccess to the company
    if(this.activeUserInfo.companyId != this.$route.params.id) {
      this.$router.push('/error-404')
    }

    this.$http
      .get(`api/companies/settings/${this.$route.params.id}`)
      .then(resp => { 
        var model = resp.data
        this.form.companyName = model.name
        this.form.companyType = model.type
        this.form.logoUrl = model.logoUrl
        this.form.address1 = model.address1
        this.form.address2 = model.address2
        this.form.state = model.state
        this.form.city = model.city
        this.form.countryCode = model.countryCode
        this.form.requireUsersTo2FA = model.requires2FA
        this.selectedCountry = this.countryOptions.find(x => x.code == model.countryCode) || {}
        this.selectedCompanyType = this.companyTypeOptions.find(x => x.code == model.type) || {}
      })
      .catch((error) => {

      })
  },
  methods: {
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    setSelectedCompanyType (value) {
      this.form.companyType = value ? value.code : null
      this.selectedCompanyType = value;
    },
    handleFileUpload () {
      this.file = this.$refs.companyLogoInput.files[0];
      if (this.file) this.submitLogoIcon();
    },
    submitLogoIcon () {
      let formData = new FormData();
      formData.append('logoFile', this.file);
      this.$vs.loading()
      this.$http.post( 'api/companies/settings/logo', formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
      })
      .then(response => {
        this.form.logoUrl = response.data.logoUrl
        this.$_notifySuccess('Logo successfully uploaded')
      })
      .catch(error => this.$_notifyFailure('Logo failed to upload'))
      .finally(() => this.$vs.loading.close())
    },
    submitSettings () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.post(`api/companies/settings/${this.$route.params.id}`)
            .then(response => {
              this.$_notifySuccess('Company settings successfully saved');
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }
  /* .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    background: #787879;
    border: none;
    color: #664f39;
    text-transform: lowercase;
    font-variant: small-caps;
  }

  .style-chooser .vs__clear,
  .style-chooser .vs__open-indicator {
    fill: #394066;
  } */
</style>